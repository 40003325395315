import * as React from "react"
import { graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from "../components/layout";
import SecondaryClouds from '../components/secondary-clouds'
import { GatsbyImage } from "gatsby-plugin-image";


const TemplatesPage = ({data}) => {
  const templatePage = data.templatePage
  return (
    <Layout>
      <HelmetDatoCms 
        seo={data.templatePage.seoMetaTags}
      />
      <main className="templates-page-container">
        <div className="templates-page-title">
          <h1>{templatePage.title}</h1>
            <div class="filters">
              <h6>{templatePage.filter}:</h6>
              {data.tags.nodes.map(tag => (
                <Link 
                to={`/templates/filter/${tag.slug}`}
                activeClassName="active"
                className="template-tag"
                key={tag.id}
                >
                    <span class="btn-text">{tag.name}</span>
                </Link>
              ))}
          </div>
        </div>
        <section className="templates-grid">
          {data.templates.nodes.map(template => (
            <a className="template-item"
            key={template.id}
            href={`${templatePage.url}?${template.linkedTemplate.slug}`}>
              <GatsbyImage image={template.image.gatsbyImageData} alt={template.image.alt} />
              <h5>{template.linkedTemplate.name}</h5>
              <p>{template.description}</p>
              <span className="btn btn--purple"><span className="btn-text">{templatePage.cta}</span></span>
            </a>
          ))}
        </section>
        <SecondaryClouds />
      </main>
    </Layout>
  )
}
export default TemplatesPage

export const query = graphql`
  query Templates($language: String!) {
  templatePage: datoCmsTemplatesPage(locale: {eq: $language}) {
    title
    url
    cta
    filter
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
  }
  tags: allDatoCmsTemplateTag(filter: {locale: {eq: $language}}) {
    nodes {
      slug
      name
      id
    }
  }
  templates: allDatoCmsTemplate(filter: {locale: {eq: $language}}) {
    nodes {
      id
      description
      image {
        gatsbyImageData(layout: FULL_WIDTH)
        alt
        width
        height
      }
      linkedTemplate {
        name
        slug
      }
    }
  }
}
`